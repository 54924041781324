import {STEP_MAPPER} from '../constants';

import Welcome from './Welcome';
import Address from './Address';
import FilledAddress from './FilledAddress';
import PropertyTitle from './PropertyTitle';
import BorrowerOnLoan from './BorrowerOnLoan';
import CoBorrower from './CoBorrower';
import CoBorrowerProperty from './CoBorrowerProperty';
import BorrowerInfo from './BorrowerInfo';
import PropertyTrust from './PropertyTrust';
import TypeOfHome from './TypeOfHome';
import PropertyUsage from './PropertyUsage';
import ReasonForRenovating from './ReasonForRenovating';
import TypeOfRenovation from './TypeOfRenovation';
import Finish from './Finish';

// don't use 0.1 step because of floating point math in JS
// TODO: replace stepNumber with stepName
const getStepComponentByStepNumber = (step) => {
  switch (step) {
    case STEP_MAPPER.Welcome:
      return Welcome;
    case STEP_MAPPER.FilledAddress:
      return FilledAddress;
    case STEP_MAPPER.Address:
      return Address;
    case STEP_MAPPER.PropertyTrust:
      return PropertyTrust;
    case STEP_MAPPER.PropertyTitle:
      return PropertyTitle;
    case STEP_MAPPER.TypeOfHome:
      return TypeOfHome;
    case STEP_MAPPER.PropertyUsage:
      return PropertyUsage;
    case STEP_MAPPER.TypeOfRenovation:
      return TypeOfRenovation;
    case STEP_MAPPER.ReasonForRenovating:
      return ReasonForRenovating;
    case STEP_MAPPER.BorrowerOnLoan:
      return BorrowerOnLoan;
    case STEP_MAPPER.CoBorrower:
      return CoBorrower;
    case STEP_MAPPER.CoBorrowerProperty:
      return CoBorrowerProperty;
    case STEP_MAPPER.BorrowerInfo:
      return BorrowerInfo;
    case STEP_MAPPER.Finish:
      return Finish;
    default:
      return () => null;
  }
};

export default getStepComponentByStepNumber;
