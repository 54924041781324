export default function useTogglePropertySteps({isSubjectProperty} = {}) {
  return (toggleStep, route, property) => {
    const {
      existingMortgageLoan,
      homeownersAssociationFee, // Float
      homeownersAssociationFeesIncluded,
      homeownersAssociationFeesExist,
      propertyMonthlyTaxes, // Float
      taxAndInsuranceIncluded,
      intendedOccupancy,
      propertyUseType,
      isLandLoan,
      rentalIncome,
    } = property || {};
    const hasNoMortgage = !existingMortgageLoan;

    toggleStep(
      `${route}/mortgage-statement`,
      existingMortgageLoan === undefined || existingMortgageLoan === true,
    );
    toggleStep(
      `${route}/insurance`,
      (isSubjectProperty && !isLandLoan) ||
        (!isSubjectProperty &&
          (taxAndInsuranceIncluded === false || hasNoMortgage)),
    );
    toggleStep(
      `${route}/tax`,
      propertyMonthlyTaxes ||
        taxAndInsuranceIncluded === false ||
        hasNoMortgage,
    );
    toggleStep(
      `${route}/fees`,
      Boolean(
        homeownersAssociationFee ||
          (homeownersAssociationFeesExist === true &&
            homeownersAssociationFeesIncluded === false),
      ),
    );
    toggleStep(
      `${route}/lease`,
      (intendedOccupancy === 'investment_property' ||
        propertyUseType === 'investment_property') &&
        rentalIncome > 0,
    );
  };
}
