import {isZipCode} from '@renofi/utilities';

import {STEP_MAPPER} from './constants';

export const transformAddressToDbSchema = (address) => {
  const {
    address: propertyStreetAddressOne,
    secondAddress: propertyStreetAddressTwo,
    city: propertyCity,
    county: propertyCounty,
    state: propertyState,
    zipCode: propertyZipCode,
  } = address;

  return {
    propertyStreetAddressOne,
    propertyStreetAddressTwo,
    propertyCity,
    propertyCounty,
    propertyState,
    propertyZipCode,
  };
};

export const validateByCurrentStep = ({info, step}) => {
  switch (step) {
    case STEP_MAPPER.Welcome:
    case STEP_MAPPER.Finish:
      return true;
    case STEP_MAPPER.FilledAddress:
    case STEP_MAPPER.Address:
      return Boolean(
        info.propertyStreetAddressOne &&
          info.propertyState &&
          info.propertyCity &&
          isZipCode(info.propertyZipCode),
      );
    case STEP_MAPPER.TypeOfHome:
      return Boolean(info?.propertyStructureType);
    case STEP_MAPPER.PropertyUsage:
      return Boolean(info?.propertyUseType);
    case STEP_MAPPER.TypeOfRenovation:
      return Boolean(info?.renovationTypeDescription);
    case STEP_MAPPER.ReasonForRenovating:
      return Boolean(info?.reasonForRenovating);
    case STEP_MAPPER.BorrowerInfo:
      return (
        Boolean(info?.coborrowerFirstName) && Boolean(info?.coborrowerLastName)
      );
    default:
      return false;
  }
};
