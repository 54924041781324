import React from 'react';

import PropTypes from 'prop-types';
import ManageDocuments from 'modules/tasks-wizard/components/ManageDocuments';

import {useSubmitProjectDocument} from '@renofi/graphql';

import {Content, Title} from '../styled';

const LeaseStep = ({
  task,
  address,
  realEstateOwnedPropertyId,
  documents,
  onUploadComplete,
}) => {
  const {submitProjectDocument} = useSubmitProjectDocument();
  const {id: taskId, facet} = task;

  return (
    <Content>
      <Title>
        Next, upload <strong>lease/rental agreement</strong> for {address}.
      </Title>

      <ManageDocuments
        forceRemoveButton
        customDocuments={documents}
        taskId={taskId}
        facet={facet}
        fileName="lease agreement"
        customMutation={submitProjectDocument}
        customResponsePath="submitProjectDocument"
        customMutationParams={{
          realEstateOwnedPropertyId,
          documentType: 'lease_agreement',
          attachedRecordTarget: {
            id: realEstateOwnedPropertyId,
            type: 'real_estate_owned_property',
          },
        }}
        onUploadComplete={onUploadComplete}
      />
    </Content>
  );
};

LeaseStep.propTypes = {
  task: PropTypes.object,
  address: PropTypes.string,
  realEstateOwnedPropertyId: PropTypes.string,
  documents: PropTypes.array,
  onUploadComplete: PropTypes.func,
};

LeaseStep.defaultProps = {
  documents: [],
};

export default LeaseStep;
