import React from 'react';

import PropTypes from 'prop-types';
import {reject} from 'lodash';

import {
  SelectField,
  Text,
  Box,
  Textarea,
  TextField,
  Grid,
} from '@renofi/components-internal';

import {Content, Title} from '../styled';
import ApplicantsSelect from '../ApplicantsSelect';
import useReoEnums from '../hooks/useReoEnums';

const NOT_OCCUPIED = 'not_occupied';
const OTHER = 'other';

const IGNORED_TYPES = [NOT_OCCUPIED, OTHER];

const AdditionalPropertyDetailsStep = ({
  onChange,
  property,
  borrower,
  coborrower,
  borrowersInfo,
}) => {
  const {occupancyTypes, propertyStatusTypes, propertyTypes} = useReoEnums();
  const intendedOccupancyTypes = reject(occupancyTypes, ({value}) =>
    IGNORED_TYPES.includes(value),
  );

  const {
    currentOccupancyOther,
    intendedOccupancy,
    propertyStatus,
    rentalIncome,
    marketValue,
    yearPurchased,
    streetAddressOne,
    coborrowerOwned,
    primaryBorrowerOwned,
    propertyStructureType,
    propertyUseType,
  } = property || {};
  const showIntended = propertyUseType === NOT_OCCUPIED;
  const showRentalIncome =
    propertyStatus === 'rental' ||
    intendedOccupancy === 'investment_property' ||
    propertyUseType === 'investment_property';

  return (
    <Content>
      <Title>
        Now, provide <strong>details</strong> for the {streetAddressOne}{' '}
        property.
      </Title>

      <Grid columns={2}>
        <SelectField
          name="propertyStructureType"
          value={propertyStructureType}
          label="Property type"
          options={propertyTypes}
          onChange={(value) => onChange({propertyStructureType: value})}
        />
        <SelectField
          name="propertyUseType"
          value={propertyUseType}
          label="Current occupancy"
          options={occupancyTypes}
          onChange={(value) => onChange({propertyUseType: value})}
        />

        {propertyUseType === OTHER ? (
          <Box css={{gridColumn: 'span 2'}}>
            <Textarea
              rows={3}
              value={currentOccupancyOther}
              label="If other, provide details"
            />
          </Box>
        ) : null}

        {showIntended ? (
          <SelectField
            name="intendedOccupancy"
            value={intendedOccupancy}
            label="Intended Occupancy"
            options={intendedOccupancyTypes}
            onChange={(value) => onChange({intendedOccupancy: value})}
          />
        ) : null}

        <SelectField
          name="propertyStatus"
          value={propertyStatus}
          label="Status of property"
          options={propertyStatusTypes}
          onChange={(value) => onChange({propertyStatus: value})}
        />

        {showRentalIncome ? (
          <TextField
            money
            showIconDivider
            help="/month"
            leftIcon="dollar"
            name="rentalIncome"
            value={rentalIncome}
            label="Rental income"
            onChange={(value) => {
              return onChange({rentalIncome: value === '' ? null : value});
            }}
          />
        ) : null}
        <TextField
          money
          showIconDivider
          leftIcon="dollar"
          name="marketValue"
          value={marketValue}
          label="Current market value"
          onChange={(value) => onChange({marketValue: value})}
        />
        <TextField
          numeric
          pattern="[0-9]+"
          showIconDivider
          leftIcon="calendar"
          name="yearPurchased"
          value={yearPurchased}
          label="Year purchased"
          onChange={(value) => onChange({yearPurchased: value})}
        />
      </Grid>

      {borrowersInfo?.coborrowerIsPresent ? (
        <>
          <Text fontSize={20} mb={16}>
            Ownership
          </Text>
          <ApplicantsSelect
            onChange={onChange}
            borrower={borrower}
            coborrower={coborrower}
            coborrowerOwned={coborrowerOwned}
            primaryBorrowerOwned={primaryBorrowerOwned}
            borrowersInfo={borrowersInfo}
          />
        </>
      ) : null}
    </Content>
  );
};

AdditionalPropertyDetailsStep.propTypes = {
  property: PropTypes.object,
  formData: PropTypes.object,
  onChange: PropTypes.func,
  borrower: PropTypes.object,
  coborrower: PropTypes.object,
  borrowersInfo: PropTypes.object,
};

export default AdditionalPropertyDetailsStep;
